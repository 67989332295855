import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CustomerInfoRestService extends BaseService {
  private url = `${this.endpointUrl}/v1/customer-info`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }
}
