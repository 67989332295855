import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@archimed-frontend/archimed-common';
import { MatomoTracker } from 'ngx-matomo-client';
import { AppStateService } from '../../shared/app-state.service';
import { marker as transKey } from '@colsen1991/ngx-translate-extract-marker';
import { GuiMessageService } from '../../shared/message/gui-message.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  model: any = {};
  returnUrl: string | undefined;

  // Note: move to state
  loading = { active: false };

  state$ = this.appState.select();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appState: AppStateService,
    private authenticationService: AuthenticationService,
    private matomoTracker: MatomoTracker,
    private guiMessageService: GuiMessageService,
    public platform: Platform
  ) {}

  ngOnInit() {
    this.authenticationService.setLoggedIn(false);

    // this is not necessary - loading of the piwik js is already counted as a visit
    // this.matomoTracker.setDocumentTitle(this.titleService.getTitle());
    // this.matomoTracker.setCustomUrl("/login");
    // this.matomoTracker.trackPageView();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    console.log('login');
    this.loading = { active: true };
    this.authenticationService
      .login(this.model.username, this.model.password)
      .subscribe({
        next: () => {
          this.matomoTracker.trackEvent('User', 'Login', this.model.username);
          if (this.returnUrl === '/login' || this.returnUrl === '/logout') {
            this.returnUrl = '/dashboard';
          }
          this.router.navigate([this.returnUrl]);
        },
        error: (error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.guiMessageService.showError(
              transKey('login.alert.no-user-with-these-credentials')
            );
          } else {
            // Note: translate
            this.guiMessageService.showError('Error logging in.');
          }
          this.loading = { active: false };
        },
      });
  }
}
