import { Country } from './country';

export class CustomerInfo {
  portalUser: number;
  customerId: string;
  billingAddressOrganization: string;
  billingAddressStreet: string;
  billingAddressCountry: Country;
  billingAddressCity: string;
  billingAddressZip: string;
  taxId: string;
  invoiceEmail: string;
  paymentMethod: string;
  currency: string;
}
