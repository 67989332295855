import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../service/rest/user.service';
import { AuthState } from './auth.state';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RoleGuard {
  returnUrl = '/';

  constructor(
    private router: Router,
    private authState: AuthState,
    private authService: AuthenticationService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canActivateChild(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (this.authService.isLoggedIn()) {
      return this.authState.selectCurrentUser().pipe(
        map((user) => {
          if (user?.id == null) {
            return false;
          } else {
            if (
              childRoute.data['prohibit'] &&
              UserService.hasRoles(user, childRoute.data['prohibit'])
            ) {
              return this.router.parseUrl('/');
            }
            if (childRoute.data['roles']) {
              return UserService.hasRoles(user, childRoute.data['roles'])
                ? true
                : this.router.parseUrl('/');
            } else {
              return true;
            }
          }
        })
      );
    }
    // not logged in so redirect to login page with the return url
    const redirect: UrlTree = this.router.parseUrl('/login');
    redirect.queryParams = { returnUrl: this.returnUrl };
    return of(redirect);
  }
}
