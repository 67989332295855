import { Injectable, InjectionToken } from '@angular/core';

@Injectable()
export class AppSettings {
  CURRENT_VERSION: string;
  API_ENDPOINT: string;
  PDF_FOLDER: string;
  DOWNLOAD_FOLDER: string;
  IMAGE_FOLDER: string;
  BACKOFFICE: boolean;
}

export const APP_CONFIG = new InjectionToken<AppSettings>('AppConfig');
