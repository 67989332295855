import { AppSettings } from '@archimed-frontend/archimed-common';

export const ARCHIMED_CONFIG: AppSettings = {
  CURRENT_VERSION: '0.9',
  API_ENDPOINT: '/api',
  PDF_FOLDER: '/api/resources',
  DOWNLOAD_FOLDER: '/api/public/download',
  IMAGE_FOLDER: '../../assets/img',
  BACKOFFICE: false,
};
