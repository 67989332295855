import { Injectable } from '@angular/core';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private status = new Subject<any>();
  private _active: boolean = false;

  public get active(): boolean {
    return this._active;
  }

  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  public start(): void {
    this.active = true;
  }

  public stop(): void {
    this.active = false;
  }

  getStatus(): Observable<any> {
    return this.status.asObservable();
  }

  public getLoadingEventHandler(loadingEvent: Subject<boolean>) {
    return loadingEvent.pipe(
      switchMap((loading) => {
        return loading ? of(loading).pipe(delay(300)) : of(loading);
      }),
      map((loading) => {
        return { loading };
      })
    );
  }
}
