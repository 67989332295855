import { PortalUser } from './portaluser';

export class Physician extends PortalUser {
  institution: string;
  position: string;
  department: string;
  specialization: string;
  licenceNumber: string;
  phone: string;
  fax: string;
  city: string;
  street: string;
  zip: string;
  receivenewsletter: boolean;
  // physicians: Physician[];
  parentPhysicianId: number;
  metaRoleActive: boolean;
  mayDownload: boolean;
  override deactivationDate: any; // date or string, just makes it easier the way it is
  whatsapp: string;
  watoken: string;
  mailsDisabled: boolean;
  representedBy: number;
  currency: string;
}
