import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AnalysisPool,
  APP_CONFIG,
  AppSettings,
  Country,
  PagedResult,
  Portal,
} from '../../../index';
import { BaseService } from '../base.service';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable()
export class PoolService extends BaseService {
  private apiUrl = this.endpointUrl + '/v1/pool';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getAllAdmin(
    portal: Portal,
    filterHidden: boolean
  ): Observable<AnalysisPool[]> {
    const headers = this.getHeadersWithPortal(portal);
    let params: HttpParams = new HttpParams();
    if (filterHidden != null) {
      params = params.set('filterHidden', filterHidden);
    }
    return this.http.get<AnalysisPool[]>(`${this.apiUrl}/all`, {
      headers,
      params,
    });
  }

  getAllBillable(portal: Portal): Observable<AnalysisPool[]> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.get<AnalysisPool[]>(`${this.apiUrl}/all/billable`, {
      headers,
    });
  }

  getPaged(
    lazyLoadEvent: TableLazyLoadEvent,
    portal?: Portal
  ): Observable<PagedResult<AnalysisPool>> {
    const headers =
      portal != null ? this.getHeadersWithPortal(portal) : new HttpHeaders();
    const params = this.getPaginationHttpParams(lazyLoadEvent);
    return this.http.post<PagedResult<AnalysisPool>>(
      `${this.apiUrl}/paged`,
      lazyLoadEvent.filters,
      { params, headers }
    );
  }

  updatePool(pool: AnalysisPool): Observable<AnalysisPool> {
    return this.http.put<AnalysisPool>(`${this.apiUrl}/update`, pool);
  }

  clonePool(
    portal: Portal,
    poolId: number,
    countryId?: number,
    userId?: number
  ): Observable<AnalysisPool> {
    const headers =
      portal != null ? this.getHeadersWithPortal(portal) : new HttpHeaders();
    let params: HttpParams = new HttpParams();
    if (countryId != null) {
      params = params.set('country', countryId);
    } else if (userId != null) {
      params = params.set('user', userId);
    }
    return this.http.post<AnalysisPool>(
      `${this.apiUrl}/${poolId}/clone`,
      poolId,
      { headers, params }
    );
  }

  createPool(portal: Portal, pool: AnalysisPool): Observable<AnalysisPool> {
    const headers =
      portal != null ? this.getHeadersWithPortal(portal) : new HttpHeaders();
    return this.http.post<AnalysisPool>(`${this.apiUrl}/create`, pool, {
      headers,
    });
  }

  getCountriesForPool(poolId: number): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.apiUrl}/${poolId}/countries`);
  }

  setCountriesForPool(
    poolId: number,
    countries: Country[]
  ): Observable<Country[]> {
    return this.http.put<Country[]>(
      `${this.apiUrl}/${poolId}/countries`,
      countries
    );
  }
}
