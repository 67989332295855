import { AnalysisTemplate } from './analysisTemplate';

export class AnalysisItem {
  id: string;
  dlxLabId: string;
  poolId: number;
  analysisTemplate: AnalysisTemplate;
  subAnalysisTemplate: AnalysisTemplate;
  price: number;
  currency: string;
  subTemplateToken: string;
  templateToken: string;
}
