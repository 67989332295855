import {
  HttpClient,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { catchError, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../model/appsettings';
import { BaseService } from '../service/base.service';
import { marker as transKey } from '@colsen1991/ngx-translate-extract-marker';

// We use this custom URLEncoder because the HttpParams one from Angular does not encode '+', instead just
// replaces it with 'space'. Therefore, we have problems with passwords including '+' signs.
// AP-658
// https://stackoverflow.com/questions/51942703/character-converting-into-space-in-httpparams-angular-6

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(key: string): string {
    return encodeURIComponent(key);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(key: string) {
    return decodeURIComponent(key);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService {
  private oauthUrl = this.endpointUrl + '/auth/login';

  private headers = new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded'
  );

  refresh: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  refreshSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) private config: AppSettings,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    private translateService: TranslateService
  ) {
    super(config);
  }

  public initAutoLogout(validTime: number) {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    this.refreshSubscription = this.refresh
      .pipe(
        switchMap((expiryTime: number) => interval(expiryTime * 1000)),
        takeWhile((val) => val > 0)
      )
      .subscribe({
        error: (error) => console.error('Auto logout error.', error),
        complete: () => {
          if (this.refresh.value !== -1) {
            this.messageService.add({
              severity: 'warn',
              summary: this.config.BACKOFFICE
                ? 'Your session has expired.'
                : transKey('$global.alert.your-session-has-expired'),
            });
          }
          this.logout(false);
          this.navigateToLogin();
          console.log('Logged out after session timeout.');
        },
      });
    this.refresh.next(validTime);
  }

  public login(username: string, password: string): Observable<void> {
    const body = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('username', username)
      .set('password', password);
    return this.http
      .post<{ sessionTimeout: number }>(this.oauthUrl, body, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          this.setLoggedIn(true, response.sessionTimeout);
          this.initAutoLogout(response.sessionTimeout);
        })
      );
  }

  // should not be called from outside. Trigger a logout by calling authService.refresh.next(-1)
  logout(clientOnly: boolean) {
    // remove user from local storage to log user out
    if (!clientOnly) {
      this.http
        .get(this.endpointUrl + '/auth/logout')
        .pipe(
          tap(() => {
            console.log('Successfully logged out.');
            this.refresh.next(-1);
            this.setLoggedIn(false);
          }),
          catchError((error) => {
            console.error('Error logging out.');
            this.refresh.next(-1);
            this.setLoggedIn(false);
            return error;
          })
        )
        .subscribe();
    } else {
      console.log('Client successfully logged out.');
      this.refresh.next(-1);
      this.setLoggedIn(false);
    }
  }

  navigateToLogin() {
    this.router.navigate([
      '/login/' +
        (this.translateService.currentLang
          ? this.translateService.currentLang
          : ''),
    ]);
  }

  private readonly loggedInItemName = 'logged-in';

  public setLoggedIn(loggedIn: boolean, sessionTimeOut?: number) {
    if (loggedIn) {
      if (!sessionTimeOut) {
        throw new Error('No session timout provided.');
      }
      sessionStorage.setItem(this.loggedInItemName, sessionTimeOut?.toString());
    } else {
      sessionStorage.removeItem(this.loggedInItemName);
    }
  }

  public isLoggedIn() {
    return sessionStorage.getItem(this.loggedInItemName) !== null;
  }

  public getSessionTimeOut() {
    return Number.parseFloat(sessionStorage.getItem(this.loggedInItemName));
  }
}
