import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { catchError, map } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Analysis } from '../../model/analysis';
import { TreeNode } from 'primeng/api';
import { SupportedLanguage } from '../../model/supportedLanguage';

@Injectable()
export class TranslationService extends BaseService {
  private url = `${this.endpointUrl}/v1/translation`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  updateJsons(): Observable<any> {
    return this.http
      .get(this.url + '/update-jsons')
      .pipe(catchError(this.handleError<Analysis>('updateJsons')));
  }

  getSupportedLanguages(): Observable<SupportedLanguage[]> {
    // let params: HttpParams = new HttpParams();

    return this.http
      .get<SupportedLanguage[]>(`${this.url}/list`)
      .pipe(
        catchError(
          this.handleError<SupportedLanguage[]>('getSupportedLanguagesAdmin')
        )
      );
  }

  getTranslationsListing(
    folderName: string,
    mode?: string
  ): Observable<TreeNode[]> {
    let params: HttpParams = new HttpParams();

    if (mode != null) {
      params = params.set('mode', `${mode}`);
    }

    return this.http
      .get<TreeNode[]>(`${this.url}/list/${folderName}?${params.toString()}`)
      .pipe(catchError(this.handleError<TreeNode[]>('getTranslationsListing')));
  }

  getTranslationFile(fileName: string): Observable<string> {
    return this.http.get(`${this.url}/download/${fileName}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<string>('downloadFile'))
    );
  }

  getTranslationJSON(
    translationSet: string,
    langKey: string,
    backend?: boolean
  ): Observable<string> {
    let params: HttpParams = new HttpParams();

    if (backend != null) {
      params = params.set('backend', `${backend}`);
    }
    return this.http
      .get(`${this.url}/json/${translationSet}/${langKey}`, { params })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError<string>('getTranslationJSON'))
      );
  }

  getUploadUrl(translationSet: string, langKey?: string, backend?: boolean) {
    const language = langKey != null ? `/${langKey}` : '';
    return `${this.url}/json/${translationSet}${language}${
      backend ? '?backend=true' : ''
    }`;
  }
}
