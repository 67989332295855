import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { BaseService } from '../base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Price } from '../../model/price';

@Injectable({ providedIn: 'root' })
export class PriceRestService extends BaseService {
  private url = `${this.endpointUrl}/v1/price`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  public removeDefaultPrice(templateId: number, currency: string) {
    const params = new HttpParams()
      .set('template-id', templateId)
      .set('currency', currency);
    return this.http.delete<void>(`${this.url}/default`, { params });
  }

  public addDefaultPrice(templateId: number, price: Price) {
    const params = new HttpParams().set('template-id', templateId);
    return this.http.post<void>(`${this.url}/default`, price, { params });
  }

  public saveDefaultPrice(templateId: number, price: Price) {
    const params = new HttpParams().set('template-id', templateId);
    return this.http.put<void>(`${this.url}/default`, price, { params });
  }

  public getCurrencies() {
    return this.http.get<string[]>(`${this.url}/currencies`);
  }
}
