import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthState } from './auth.state';
import { AuthenticationService } from './authentication.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../service/rest/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  returnUrl = '/';

  constructor(
    private router: Router,
    private authState: AuthState,
    private authService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      // We trigger loading currentUser into the AppState here, so in can ActivateChild we can be sure the
      // there is a user in the appState and we can do additional checks (e.g. roles, permissions) there
      this.authState.fetchCurrentUser();

      // if page has been refreshed with F5, restart timer
      // Note: this is technically incorrect, as it resets the timer to a full new period
      // of the refresh_token expires_in from the sessionStorage; the time passed between
      // fetching the token and pressing F5 will be ignored. Therefore, we can have situations
      // where the refresh token expired on the server but the client still thinks it is valid
      // therefore we should always make a refresh call on F5; delta is bound by access_token
      // validity (e.g. 60 sec)
      if (!this.authService.refreshSubscription) {
        this.authService.initAutoLogout(this.authService.getSessionTimeOut());
      }
      return true;
    }

    if (state.url !== '/login' && state.url !== '/logout') {
      this.returnUrl = state.url;
    }
    // not logged in so redirect to login page with the return url
    const redirect: UrlTree = this.router.parseUrl('/login');
    redirect.queryParams = { returnUrl: this.returnUrl };
    return redirect;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (this.authService.isLoggedIn()) {
      return this.authState.selectCurrentUser().pipe(
        map((user) => {
          if (user?.id == null) {
            return false;
          } else {
            if (childRoute.data['roles']) {
              return UserService.hasRoles(user, childRoute.data['roles'])
                ? true
                : this.router.parseUrl('/');
            } else {
              return true;
            }
          }
        })
      );
    }
    // not logged in so redirect to login page with the return url
    const redirect: UrlTree = this.router.parseUrl('/login');
    redirect.queryParams = { returnUrl: this.returnUrl };
    return of(redirect);
  }
}
