import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PagedRequest } from './pagedRequest';
import { GuiMessageInterface } from '../message/gui-message.interface';
import { TableLazyLoadEvent } from 'primeng/table';
import { throwError } from 'rxjs';

export class RestUtils {
  static lazyLoadEventToPagedRequest(
    event: LazyLoadEvent | TableLazyLoadEvent
  ): PagedRequest {
    const pagedRequest = new PagedRequest();
    pagedRequest.firstRow = event.first;
    pagedRequest.rows = event.rows;
    pagedRequest.filters = event.filters;
    return pagedRequest;
  }

  static pagedRequestToHttpParams(
    pagedRequest: PagedRequest,
    httpParams?: HttpParams
  ): HttpParams {
    httpParams = httpParams ?? new HttpParams();
    if (pagedRequest?.rows != null) {
      const rows = pagedRequest.rows;
      httpParams = httpParams.set('size', rows);
      if (pagedRequest?.firstRow != null) {
        const firstRow = pagedRequest.firstRow;
        const page = Math.trunc(firstRow / rows);
        const remainder = firstRow % rows;
        if (remainder !== 0) {
          console.error(
            `PagedRequest parameters do not fit (rows=${rows}, firstRow=${firstRow}`
          );
        }
        httpParams = httpParams.set('page', page);
      }
    }
    return httpParams;
  }

  static getPaginationHttpParams(
    event: LazyLoadEvent | TableLazyLoadEvent,
    httpParams?: HttpParams
  ): HttpParams {
    return this.pagedRequestToHttpParams(
      this.lazyLoadEventToPagedRequest(event),
      httpParams
    );
  }

  static showHttpAwareError(
    summary: string,
    guiMessageService: GuiMessageInterface,
    key?: string
  ) {
    return (error: Error) => {
      this.showHttpError(error, summary, guiMessageService, key);
      throw error;
    };
  }

  static showHttpError(
    error: Error,
    summary: string,
    guiMessageService: GuiMessageInterface,
    key?: string
  ) {
    let detail: string;
    if (error instanceof HttpErrorResponse && error.error.message != null) {
      detail = error.error.message;
    } else {
      detail = 'An unknown error occurred.';
    }
    guiMessageService.showError(summary, detail, key);
  }

  /**
   * Converts a blob error message to a JSON error message.
   *
   * @param error Error with message to be converted to JSON.
   */
  static handleErrorResultInBlobRequest(error) {
    if (
      error.error instanceof Blob &&
      error.error.type === 'application/json'
    ) {
      return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          try {
            const errorBody = JSON.parse(e.target.result as string);
            const customError = new HttpErrorResponse({
              error: errorBody,
              headers: error.headers,
              status: error.status,
              statusText: error.statusText,
              url: error.url,
            });
            reject(customError);
          } catch (jsonError) {
            reject(error);
          }
        };
        reader.onerror = () => reject(error);
        reader.readAsText(error.error);
      });
    }
    return throwError(() => error);
  }
}
